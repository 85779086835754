@import '../responsive.scss';

.App {
  height: 100%;
  background: #f5f8f8;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  $particleSize: 20em;
  $animationDuration: 6s;
  $amount: 20;
  $opacity: 0.4;
  span.bokeh-dot {
    @include respond-to(smallDesktop) { display: none; animation: none !important; }
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (
      #f99,
      #ff9,
      #f9f,
      #999,
      #99f,
      #9f9,
      #9ff,
      #fff
    );
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        opacity: $opacity;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
      }
    }
  }

  @keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
}