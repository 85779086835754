@import '../responsive.scss';

.Container {
  background: #fff;
  max-width: 950px;
  margin: 5em auto 2em;
  @include respond-to(smallDesktop) { margin: 0; }
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.Container a {
  text-decoration: none;
}
.Container em {
  font-style: italic;
}